<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          {{ year }} &nbsp;&copy;&nbsp;
        </span>
        <a
          href="http://deepcode.uz"
          target="_blank"
          class="text-dark-75 text-hover-primary"
        >
          Deepcode
        </a>
      </div>
      <div class="nav nav-dark">
        <a href="http://deepcode.uz" target="_blank" class="nav-link pr-3 pl-0">
          Biz haqimizda
        </a>
        <a href="http://deepcode.uz" target="_blank" class="nav-link px-3">
          Jamoa
        </a>
        <a href="http://deepcode.uz" target="_blank" class="nav-link pl-3 pr-0">
          Biz bilan bog'lanish
        </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from 'vuex'
const fullyear = new Date().getFullYear()
export default {
  name: 'KTFooter',
  computed: {
    ...mapGetters(['layoutConfig']),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig('footer.width') === 'fluid'
    },
    year() {
      return fullyear
    }
  }
}
</script>
