var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('KTHeaderMobile'),_c('transition',{attrs:{"name":"fade","appear":""}},[(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[(_vm.asideEnabled)?_c('KTAside'):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper",attrs:{"id":"kt_wrapper"}},[_c('KTHeader'),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid p-0",attrs:{"id":"kt_content"}},[(_vm.subheaderDisplay)?_c('KTSubheader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":_vm.pageTitle}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{class:[
              'd-inherit',

              {
                'container-fluid': _vm.contentFluid,
                container: !_vm.contentFluid
              }
            ]},[_c('v-main',[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)],1)])],1),_c('KTFooter')],1)],1),_c('KTScrollTop')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }