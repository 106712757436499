<template>
  <ul class="menu-nav">
    <li class="menu-section">
      <h4 class="menu-text">Haydovchi</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link to="/driverzayavkas" v-slot="{ href, navigate }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-doc"></i>
          <span class="menu-text">Buyurtmalar ruyhati</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'KTMenu',
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    }
  }
}
</script>
