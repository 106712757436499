var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_vm._m(0),_c('router-link',{attrs:{"to":"/clientcontracts"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"menu-item",class:[
        _vm.isActive && 'menu-item-active',
        _vm.isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-doc"}),_c('span',{staticClass:"menu-text"},[_vm._v("Shartnoma ruyhati")])])])]}}])}),_c('router-link',{attrs:{"to":"/clientfactures"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"menu-item",class:[
        _vm.isActive && 'menu-item-active',
        _vm.isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-doc"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hisob faktura")])])])]}}])}),_c('router-link',{attrs:{"to":"/clientzayavkalist"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"menu-item",class:[
        _vm.isActive && 'menu-item-active',
        _vm.isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-doc"}),_c('span',{staticClass:"menu-text"},[_vm._v("Buyurtmalar ruyhati")])])])]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Mijoz")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
}]

export { render, staticRenderFns }