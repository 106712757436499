const i18nService = {
  defaultLanguage: 'uz',

  languages: [
    {
      lang: 'uz',
      name: 'UZ',
      flag: require('@/assets/svg/flags/190-uzbekistn.svg')
    },
    {
      lang: 'ru',
      name: 'RU',
      flag: require('@/assets/svg/flags/013-russia.svg')
    }
    // {
    //   lang: 'de',
    //   name: 'German',
    //   flag: process.env.BASE_URL + 'media/svg/flags/162-germany.svg'
    // },
    // {
    //   lang: 'fr',
    //   name: 'French',
    //   flag: process.env.BASE_URL + 'media/svg/flags/195-france.svg'
    // }
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem('language', lang)
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem('language') || this.defaultLanguage
  }
}

export default i18nService
